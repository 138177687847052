<template>
  <!-- TODO O TEMPLATE FEITO UTILIZANDO O BOOTSTRAP VUE -->
  <div class="productslist">
    <span id="loading" v-if="this.loading == true">
      <Loading />
    </span>
    <!-- BARRA DE PESQUISA -->
    <br />
    <!-- BARRA DE PESQUISA - PESQUISAR POR ENCOMENDA -->
    <!-- PAGINAÇÃO NA PARTE SUPERIOR DA TABELA -->
    <b-button class="returnOrder" @click="movement('feeds')">Feeds</b-button>
    <b-button class="lostedOrder" @click="movement('categories')">Categorias</b-button>
    <b-button class="waitAddress" @click="movement('coins')">Moedas</b-button>
    <!-- TABELA COM TODOS OS PRODUTOS -->
    <div v-if="this.page == 'feeds'">
      <h2>Feeds</h2>
      <b-button class="waitAddress" @click="openFeedModal()">Adicionar Feed</b-button>
      <b-button class="returnOrder" @click="openCustomValuesModal()">Custom Label Valores</b-button>
      <b-button class="waitAddress" @click="generateFeeds()">Gerar Feeds</b-button>
      <div class="table">
        <b-table
          striped
          hover
          :items="formattedFeeds"
          :fields="fields"
          :filter="filter"
          :per-page="perPage"
          :current-page="currentPage"
          thead-class="text-white"
          small
        >
          <template #cell(id)="data">
            {{ data.item.id }}
          </template>
          <template #cell(name)="data">
            {{ data.item.name }}
            <br />
            <a :href="'https://backoffice.searchmatters.pt/feedsCountry/' + data.item.name + '.xml'">Feed Link</a>
          </template>
          <template #cell(button)="data">
            <a id="updateValues" @click="passFeedData(data.item)">🔄</a>
            <a id="updateValues" @click="deleteFeed(data.item)">❌</a>
          </template>
        </b-table>
      </div>
    </div>
    <div v-if="this.page == 'categories'">
      <h2>Categorias</h2>
      <b-button class="waitAddress" @click="openCategoryModal()">Adicionar Categoria</b-button>
      <b-button class="lostedOrder" @click="openCategorySKUModal()">Atualizar Via SKU</b-button>
      <div class="table">
        <b-table
          striped
          hover
          :items="categories"
          :fields="fields2"
          :filter="filter"
          :per-page="perPage"
          :current-page="currentPage"
          thead-class="text-white"
          small
        >
          <template #cell(id)="data">
            {{ data.item.id }}
          </template>
          <template #cell(button)="data">
            <a id="updateValues" @click="updateFile(data.item)">🔧</a>
            <a id="updateValues" @click="downloadProducts(data.item)">⬇️</a>
            <a id="updateValues" @click="deleteCategory(data.item)">❌</a>
          </template>
        </b-table>
      </div>
    </div>
    <div v-if="this.page == 'coins'">
      <h2>Moedas</h2>
      <div class="table">
        <b-table
          striped
          hover
          :items="coins"
          :fields="fields3"
          :filter="filter"
          :per-page="perPage"
          :current-page="currentPage"
          thead-class="text-white"
          small
        >
          <template #cell(coin)="data">
            {{ data.item.coin }}
          </template>
          <template #cell(rate)="data">
            <input id="length" type="text" class="form-control" v-model="data.item.rate" />
          </template>
          <template #cell(button)="data">
            <a id="updateValues" @click="updateCoins(data.item)">🔄</a>
          </template>
        </b-table>
      </div>
    </div>
    <b-modal id="add-category" title="Adicionar Categoria!" ref="modal_add-category" hide-footer>
      <form v-on:submit.prevent="addCategories()">
        <div class="form-group">
          <label for="new_name">Nome</label>
          <input type="text" class="form-control" id="new_name_cat" v-model="new_name_cat" required />
        </div>
        <button type="submit" class="btn btn-primary">Adicionar</button>
      </form>
    </b-modal>
    <b-modal id="add-category" title="Atualizar Categorias via SKU!" ref="modal_add-category-sku" hide-footer>
      <form v-on:submit.prevent="updateViaSku()">
        <div class="form-group">
          <label for="sku">SKUs</label>
          <input
            type="text"
            class="form-control"
            id="new_name"
            v-model="up_skus"
            placeholder="Separar SKU por vírgulas"
            required
          />
          <label for="new_country">Categorias</label>
          <v-select :options="categories" v-model="up_categories_sku" label="name" multiple>
            <template slot="option" slot-scope="option"> {{ option.name }}</template>
          </v-select>
        </div>
        <button type="submit" class="btn btn-primary">Atualizar</button>
      </form>
    </b-modal>
    <b-modal id="add-prods" size="xl" title="Atualizar Produtos Categoria!" ref="modal_add-prod" hide-footer>
      <form v-on:submit.prevent="updateCategoryProducts()">
        <input type="file" class="custom-file-input" id="customFile" ref="file" @change="onFileChange" accept=".csv" />
        <label class="custom-file-label" for="customFile">{{ fileName }}</label>
        <button id="" type="submit" class="btn btn-primary">Atualizar</button>
      </form>
    </b-modal>
    <b-modal id="add-feed" title="Adicionar Feed!" ref="modal_add-feed" hide-footer>
      <form v-on:submit.prevent="addFeedCountry()">
        <div class="form-group">
          <label for="new_name">Nome</label>
          <input type="text" class="form-control" id="new_name" v-model="new_name" required />
          <label for="new_country">Países</label>
          <v-select :options="countries" v-model="new_country" label="name" multiple>
            <template slot="option" slot-scope="option"> {{ option.name }}</template>
          </v-select>
          <label for="new_country">Cat. Excluidas</label>
          <v-select :options="categories" v-model="new_categories" label="name" multiple>
            <template slot="option" slot-scope="option"> {{ option.name }}</template>
          </v-select>
          <label for="new_country">Língua</label>
          <v-select :options="language" v-model="new_language" label="name" required>
            <template slot="option" slot-scope="option"> {{ option.name }}</template>
          </v-select>
          <label for="new_country">Arredondar</label>
          <v-select :options="round" v-model="new_round" label="name" required>
            <template slot="option" slot-scope="option"> {{ option.name }}</template>
          </v-select>
          <label for="new_country">Free Shipping</label>
          <input type="number" class="form-control" id="new_free_shipping" v-model="new_free_shipping" />
          <label for="new_country">Vendas</label>
          <input type="number" class="form-control" id="new_vendas" v-model="new_vendas" />
          <label for="new_country">Data Vendas</label>
          <input type="number" class="form-control" id="new_date_vendas" v-model="new_date_vendas" />
          <b-form-checkbox id="up_only_stock" v-model="new_only_stock" switch> Apenas Stock </b-form-checkbox>
        </div>
        <button type="submit" class="btn btn-primary">Adicionar</button>
      </form>
    </b-modal>
    <b-modal id="up-feed" title="Atualizar Feed!" ref="modal_up-feed" hide-footer>
      <form v-on:submit.prevent="updateFeedCountry()">
        <div class="form-group">
          <label for="up_name">Nome</label>
          <input type="text" class="form-control" id="up_name" v-model="up_name" required />
          <label for="up_country">Países</label>
          <v-select :options="countries" v-model="up_country" label="name" multiple>
            <template slot="option" slot-scope="option"> {{ option.name }}</template>
          </v-select>
          <label for="up_country">Cat. Excluidas</label>
          <v-select :options="categories" v-model="up_categories" label="name" multiple>
            <template slot="option" slot-scope="option"> {{ option.name }}</template>
          </v-select>
          <label for="up_country">Língua</label>
          <v-select :options="language" v-model="up_language" label="name">
            <template slot="option" slot-scope="option"> {{ option.name }}</template>
          </v-select>
          <label for="new_country">Arredondar</label>
          <v-select :options="round" v-model="up_round" label="name" required>
            <template slot="option" slot-scope="option"> {{ option.name }}</template>
          </v-select>
          <label for="new_country">Free Shipping</label>
          <input type="number" class="form-control" id="up_free_shipping" v-model="up_free_shipping" />
          <label for="new_country">Vendas</label>
          <input type="number" class="form-control" id="up_vendas" v-model="up_vendas" />
          <label for="new_country">Data Vendas</label>
          <input type="number" class="form-control" id="up_date_vendas" v-model="up_date_vendas" />
          <b-form-checkbox id="up_only_stock" v-model="up_only_stock" switch> Apenas Stock </b-form-checkbox>
        </div>
        <button type="submit" class="btn btn-primary">Atualizar</button>
      </form>
    </b-modal>
    <b-modal id="custom-values" title="Adicionar Feed!" ref="modal_add-custom-values" hide-footer size="lg">
      <form v-on:submit.prevent="addFeedCountry()">
        <table class="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Min Value</th>
              <th>Med Value</th>
              <th>Max Value</th>
              <th>Peso</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="label in custom_values" :key="label.name">
              <td>{{ label.name }}</td>
              <td>
                <input type="number" class="form-control" id="up_name" v-model="label.min" />
              </td>
              <td>
                <input type="number" class="form-control" id="up_name" v-model="label.med" />
              </td>
              <td>
                <input type="number" class="form-control" id="up_name" v-model="label.max" />
              </td>
              <td>
                <input type="number" class="form-control" id="up_name" v-model="label.weight" />
              </td>
              <td>
                <a id="updateValues" @click="updateCustomValues(label)">🔄</a>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </b-modal>
    <!-- PAGINAÇÃO NO INFERIO DA TABELA -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Loading from '@/components/Loading.vue';
export default {
  components: {
    Loading,
  },
  data: function () {
    return {
      perPage: 100,
      currentPage: 1,
      page: 'feeds',
      filter: null,
      criteria: null,
      feeds: [],
      categories: [],
      rows: 1,
      loading: false,
      language: ['PT', 'ES', 'JP', 'EN'],
      round: ['CIMA', 'BAIXO', 'NORMAL'],
      fields: [
        {
          key: 'id',
          label: 'ID',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: true,
        },
        {
          key: 'name',
          label: 'Nome',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'countrys',
          label: 'Países',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: true,
        },
        {
          key: 'excluded_categories',
          label: 'Cat. Excluídas',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'language',
          label: 'Língua',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
          sortable: true,
        },
        {
          key: 'round',
          label: 'Arredondamento',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
          sortable: true,
        },
        {
          key: 'free_shipping',
          label: 'Free Shipping',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
          sortable: true,
        },
        {
          key: 'vendas',
          label: 'Vendas',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
          sortable: true,
        },
        {
          key: 'date_vendas',
          label: 'Data Vendas Inicial',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
          sortable: true,
        },
        {
          key: 'button',
          label: '',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
        },
      ],
      fields2: [
        {
          key: 'id',
          label: 'ID',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: true,
        },
        {
          key: 'name',
          label: 'Nome',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'button',
          label: '',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
        },
      ],
      fields3: [
        {
          key: 'coin',
          label: 'Moeda',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: true,
        },
        {
          key: 'rate',
          label: 'Câmbio',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'button',
          label: '',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
        },
      ],
      countries: [],
      coins: [],

      //Modal Add
      new_name: '',
      new_country: [],
      new_categories: [],
      new_language: '',
      new_round: '',
      new_only_stock: false,
      new_free_shipping: '',
      new_vendas: '',
      new_date_vendas: '',

      //Modal Update
      up_id: '',
      up_name: '',
      up_country: [],
      up_categories: [],
      up_language: '',
      up_round: '',
      up_only_stock: false,
      up_free_shipping: '',
      up_vendas: '',
      up_date_vendas: '',

      //Modal Add Categories
      new_name_cat: '',

      //File
      file: '',
      fileName: 'Escolha um ficheiro',
      fileType: '',

      //Modal Add Categories
      up_skus: [],
      up_categories_sku: [],

      //Modal Update Custom Values
      custom_values: [],
    };
  },
  methods: {
    /* FUNÇÃO ASSINCRONA QUE VAI À STORE BUSCAR OS PRODUTOS */
    async onFileChange() {
      this.file = this.$refs.file.files[0];
      console.log('Ficheiro: ', this.file);
      this.fileName = this.file.name;
      this.fileType = this.file.type;
    },
    async movement(new_page) {
      this.page = new_page;
    },
    async openFeedModal() {
      this.$refs['modal_add-feed'].show();
    },
    async openCustomValuesModal() {
      try {
        await this.$store.dispatch('getCustomLabels');
        this.custom_values = this.getAllCustomLabels;
        console.log('CUSTOM VALUES', this.custom_values);
        this.$refs['modal_add-custom-values'].show();
      } catch (error) {
        alert(error);
      }
    },
    async openCategoryModal() {
      this.$refs['modal_add-category'].show();
    },
    async openCategorySKUModal() {
      this.$refs['modal_add-category-sku'].show();
    },
    //Feeds
    async fetchAllFeedsCountry() {
      try {
        await this.$store.dispatch('getFeedsCountry');
        this.feeds = this.getAllFeedsCountry;
        console.log('FEEDS', this.feeds);
      } catch (err) {
        alert(err);
      }
    },
    async addFeedCountry() {
      try {
        await this.$store.dispatch('addFeedsCountry', {
          name: this.new_name,
          countrys: this.new_country,
          excluded_categories: this.new_categories,
          language: this.new_language,
          round: this.new_round,
          only_stock: this.new_only_stock,
          free_shipping: this.new_free_shipping,
          vendas: this.new_vendas,
          date_vendas: this.new_date_vendas,
        });
        this.$swal({
          title: 'Feed Adicionado!',
          icon: 'success',
          timer: 2000,
        });
        this.new_name = '';
        this.new_country = [];
        this.new_categories = [];
        this.new_language = '';
        this.new_round = '';
        this.new_only_stock = false;
        this.new_free_shipping = '';
        this.new_vendas = '';
        this.new_date_vendas = '';
        await this.fetchAllFeedsCountry();
        this.$refs['modal_add-feed'].hide();
      } catch (err) {
        alert(err);
      }
    },
    async passFeedData(data) {
      try {
        const feed = this.feeds.find((feed) => feed.id === data.id);
        console.log(data, feed);
        this.up_id = feed.id;
        this.up_name = feed.name;
        this.up_country = feed.countrys.map((code) => {
          return this.countries.find((country) => country.code === code);
        });
        this.up_categories = feed.excluded_categories.map((category) => {
          return this.categories.find((cat) => cat.name === category);
        });
        this.up_language = feed.language;
        this.up_round = feed.round;
        this.up_only_stock = feed.only_stock;
        this.up_free_shipping = parseInt(feed.free_shipping);
        this.up_vendas = parseInt(feed.vendas);
        if (feed.date_vendas == null) {
          this.up_date_vendas = '';
        } else {
          this.up_date_vendas = feed.date_vendas.split('T')[0];
        }
        this.$refs['modal_up-feed'].show();
      } catch (err) {
        alert(err);
      }
    },
    async updateFeedCountry() {
      try {
        await this.$store.dispatch('updateFeedsCountry', {
          id: this.up_id,
          name: this.up_name,
          countrys: this.up_country,
          excluded_categories: this.up_categories,
          language: this.up_language,
          round: this.up_round,
          only_stock: this.up_only_stock,
          free_shipping: this.up_free_shipping,
          vendas: this.up_vendas,
          date_vendas: this.up_date_vendas,
        });
        this.$swal({
          title: 'Feed Atualizado!',
          icon: 'success',
          timer: 2000,
        });
        this.up_id = '';
        this.up_name = '';
        this.up_country = [];
        this.up_categories = [];
        this.up_language = '';
        this.up_round = '';
        this.up_only_stock = false;
        this.up_free_shipping = '';
        this.up_vendas = '';
        this.up_date_vendas = '';
        await this.fetchAllFeedsCountry();
        this.$refs['modal_up-feed'].hide();
      } catch (err) {
        alert(err);
      }
    },
    async deleteFeed(data) {
      this.$swal({
        title: 'Quer eliminar o feed ' + data.id + ' ?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, eliminar!',
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$store.dispatch('deleteFeedsCountry', {
              id: data.id,
            });
            await this.fetchAllFeedsCountry();
          } catch (err) {
            alert(err);
          }
          this.$swal('Completado', 'Feed eliminado!', 'success');
        }
      });
    },
    //Categories
    async fetchAllCategories() {
      try {
        await this.$store.dispatch('getCategories');
        this.categories = this.getAllCategories;
        console.log('CATEGORIAS', this.categories);
      } catch (err) {
        alert(err);
      }
    },
    async addCategories() {
      try {
        await this.$store.dispatch('addCategory', {
          name: this.new_name_cat,
        });
        this.$swal({
          title: 'Categoria Adicionada!',
          icon: 'success',
          timer: 2000,
        });
        this.$swal({
          title: 'Feed Adicionado!',
          icon: 'success',
          timer: 2000,
        });
        this.new_name_cat = '';
        this.$refs['modal_add-category'].hide();
        await this.fetchAllCategories();
      } catch (err) {
        alert(err);
      }
    },
    async updateViaSku() {
      const categories = this.up_categories_sku.map((cat) => cat.id);
      const skuArray = this.up_skus.split(',').map((sku) => sku.trim());
      try {
        await this.$store.dispatch('updateCategoriesViaSku', {
          sku: skuArray,
          categories: categories,
        });
        this.$swal({
          title: 'Categorias Atualizadas!',
          icon: 'success',
          timer: 2000,
        });
        this.up_skus = '';
        this.up_categories_sku = [];
        this.$refs['modal_add-category-sku'].hide();
        await this.fetchAllCategories();
      } catch (err) {
        alert(err);
      }
    },
    async deleteCategory(data) {
      this.$swal({
        title: 'Quer eliminar a categoria ' + data.id + ' ?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, eliminar!',
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$store.dispatch('deleteCategory', {
              id: data.id,
            });
            await this.fetchAllCategories();
          } catch (err) {
            alert(err);
          }
          this.$swal('Completado', 'Feed eliminado!', 'success');
        }
      });
    },
    async downloadProducts(data) {
      console.log(data);
      this.up_id = data.id;
      try {
        await this.$store.dispatch('getCategoriesProducts', {
          id: data.id,
          name: data.name,
        });
      } catch (err) {
        alert(err);
      }
    },
    async updateFile(data) {
      this.up_id = data.id;
      this.$refs['modal_add-prod'].show();
    },
    async updateCategoryProducts() {
      if (this.file == '') {
        this.$swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Please select a file',
        });
        return;
      }
      if (this.fileType != 'text/csv') {
        this.$swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Please select a CSV file',
        });
        return;
      }
      try {
        this.loading = true;
        await this.$store.dispatch('updateCategoriesProducts', {
          id: this.up_id,
          file: this.file,
        });
        this.$swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'File imported successfully',
        });
      } catch (e) {
        alert(e);
      } finally {
        this.loading = false;
        this.up_id = '';
        this.$refs['modal_add-prod'].hide();
      }
    },
    //Country
    async fetchAllCountrys() {
      try {
        await this.$store.dispatch('fetchAllCountries');
        this.countries = this.getAllCountries;
        console.log('COUNTRYS', this.countries);
      } catch (err) {
        alert(err);
      }
    },
    //Generate Feeds
    async generateFeeds() {
      try {
        await this.$store.dispatch('generateFeedCountry');
        this.$swal({
          title: 'Feeds Gerados!',
          icon: 'success',
          timer: 2000,
        });
      } catch (err) {
        alert(err);
      }
    },
    //Coins
    async fetchAllCoins() {
      try {
        await this.$store.dispatch('fetchAllCoins');
        this.coins = this.getAllCoins;
        console.log('COINS', this.coins);
      } catch (err) {
        alert(err);
      }
    },
    async updateCoins(data) {
      try {
        await this.$store.dispatch('updateCoins', {
          coin: data.coin,
          rate: data.rate,
        });
        this.$swal({
          title: 'Moedas Atualizadas!',
          icon: 'success',
          timer: 2000,
        });
        await this.fetchAllCoins();
      } catch (err) {
        alert(err);
      }
    },
    //Custom Values
    async updateCustomValues(data) {
      console.log(data);
      try {
        await this.$store.dispatch('updateCustomLabels', {
          name: data.name,
          min: data.min,
          med: data.med,
          max: data.max,
          weight: data.weight,
        });
        this.$swal({
          title: 'Valores Atualizados!',
          icon: 'success',
          timer: 2000,
        });
        await this.fetchAllFeedsCountry();
      } catch (err) {
        alert(err);
      }
    },
  },
  computed: {
    ...mapGetters(['getAllCategories']),
    ...mapGetters(['getAllFeedsCountry']),
    ...mapGetters(['getProducts']),
    ...mapGetters(['getAllCategories']),
    ...mapGetters(['getAllCountries']),
    ...mapGetters(['getCategoriesProducts']),
    ...mapGetters(['getAllCoins']),
    ...mapGetters(['getAllCustomLabels']),
    formattedFeeds() {
      return this.getAllFeedsCountry.map((feed) => ({
        id: feed.id,
        name: feed.name,
        countrys: feed.countrys.join(', '),
        excluded_categories: feed.excluded_categories.join(', '),
        language: feed.language,
        round: feed.round,
        free_shipping: feed.free_shipping,
        vendas: feed.vendas,
        date_vendas: feed.date_vendas,
      }));
    },
  },
  async created() {
    this.loading = true;
    /* NA CRIAÇÃO DA PÁGINA CHAMA A FUNÇÃO QUE RETORNA TODOS OS PRODUTOS */
    await this.fetchAllFeedsCountry();
    await this.fetchAllCategories();
    await this.fetchAllCountrys();
    await this.fetchAllCoins();
    this.loading = false;
  },
};
</script>

<style scoped>
.productslist {
  text-align: center;
}
.table {
  font-size: 12px;
  margin-top: 30px;
}

/* PONTOS ONDE SURGE A COR DO STOCK */
.dot-yellow {
  height: 20px;
  width: 20px;
  background-color: goldenrod;
  border-radius: 50%;
  display: inline-block;
}
.dot-red {
  height: 20px;
  width: 20px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}
.dot-green {
  height: 20px;
  width: 20px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
}

/* BARRA DE PESQUISA */
#filter {
  width: 200px;
  margin-right: 0;
}
.searchbar {
  position: absolute;
  right: 0px;
  width: 200px;
  margin: 10px 20px 0px 0px;
}

.customPage {
  background-color: tomato;
}

h2 {
  margin-top: 20px;
}

.waitAddress {
  margin: 30px 5px 0px 5px;
  background-color: #7097b4;
  border-color: #7097b4;
}
.waitAddress:hover {
  background-color: #527d9c;
  border-color: #527d9c;
}

#updateValues {
  margin-left: 5px;
  cursor: pointer;
}
#updateValues:hover {
  cursor: pointer;
}

#list1 {
  overflow-x: auto;
}

#list2 {
  overflow-x: auto;
}

.dot-red {
  height: 20px;
  width: 20px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  margin-left: 5px;
}
.dot-green {
  height: 20px;
  width: 20px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
  margin-left: 5px;
}

.returnOrder {
  margin: 30px 5px 0px 5px;
  background-color: #8fb1cd;
  border-color: #8fb1c1;
}
.returnOrder:hover {
  background-color: #7097b4;
  border-color: #7097b4;
}
.lostedOrder {
  margin: 30px 5px 0px 5px;
  background-color: #7f9db6;
  border-color: #7f9db6;
}
.lostedOrder:hover {
  background-color: #7097b4;
  border-color: #7097b4;
}
.waitAddress {
  margin: 30px 5px 0px 5px;
  background-color: #7097b4;
  border-color: #7097b4;
}
.waitAddress:hover {
  background-color: #527d9c;
  border-color: #527d9c;
}
</style>
